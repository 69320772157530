var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"App font font--sans font--sizeDefault",attrs:{"lang":"de"}},[_c('KnAppSidebar',{staticClass:"App__sidebar"}),(_vm.isReady)?_c('MhRouterViewWrapper',{staticClass:"App__body hSpace hSpace--appBody"}):_vm._e(),_c('MhModal',{staticClass:"color color--primary",attrs:{"show":_vm.showContactModal},on:{"close":function($event){_vm.showContactModal = false}}},[_c('template',{slot:"header"},[_c('div',{staticClass:"font font--medium font--sizeBig"},[_vm._v(" Contact"),_c('br'),_c('br')])]),_c('template',{slot:"closeButton"},[_c('a',{staticClass:"circleIcon color color--primary",attrs:{"role":"button"}},[_c('MhIcon',{attrs:{"type":"cross"}})],1)]),_c('template',{slot:"body"},[_c('div',{staticClass:"richText"},[_vm._v(" In case of questions please contact:"),_c('br'),_vm._v(" • Dr. Julia Gresky "),_c('a',{attrs:{"href":"mailto:Julia.Gresky@dainst.de"}},[_vm._v("Julia.Gresky@dainst.de")]),_c('br'),_vm._v(" • Technique: cuprit GbR. "),_c('a',{attrs:{"href":"mailto:info@cuprit.net"}},[_vm._v("info@cuprit.net")]),_c('br'),_c('br')])]),_c('template',{slot:"footer"})],2),_c('MhModal',{staticClass:"color color--primary",staticStyle:{"--modalMaxWidth":"950px"},attrs:{"show":_vm.boneChangesLightbox.isOpen},on:{"close":_vm.onCloseBoneChangesLightbox}},[_c('template',{slot:"header"},[_c('div',{staticClass:"font font--medium font--sizeBig"},[_vm._v(" "+_vm._s(_vm.boneChangesLightbox.title)+" "),_c('br')])]),_c('template',{slot:"closeButton"},[_c('a',{staticClass:"circleIcon color color--primary",attrs:{"role":"button"}},[_c('MhIcon',{attrs:{"type":"cross"}})],1)]),_c('template',{slot:"body"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"0.5em"}},_vm._l((_vm.boneChangesLightbox.images),function(image,index){return _c('img',{key:'bcl' + index,style:({
						backgroundSize : 'contain',
						backgroundRepeat : 'no-repeat',
						backgroundPosition : 'center',
						backgroundColor: 'rgba(0, 0, 0, 0.15)',
						outline: '1px solid rgba(0, 0, 0, 0.5)',
						width: '100%',
						display: 'block',
					}),attrs:{"src":image.file_url}})}),0)]),_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(_vm.boneChangesLightbox.images.length)+" Image"),(_vm.boneChangesLightbox.images.length > 1)?[_vm._v("s")]:_vm._e()],2)],2),_c('MhDelegateLinks',{attrs:{"doLog":false}}),_c('MhDevInfos',{attrs:{"show":_vm.showMhDevInfos}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }